import { GitSettings } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const gitSettingsSchema: CustomSchema<GitSettings>[] = [
    {
        noStyle: true,
        shouldUpdate: () => true,
        dependencies: ["useDatabase"],
        children: ({ getFieldValue }) => {
            const useDatabase = getFieldValue("useDatabase") === true;
            if (useDatabase) {
                return <></>
            }

            return <Field
                component="input"
                name="remote"
                label="Remote"
                tooltip="Git remote URL."
                preserve={false}
                required={true}
                dependencies={['externalGit']}
                uniqe={false}
                hidden={useDatabase}
            />
        }
    },
    {
        name: "branch",
        label: "Branch",
        component: "input",
        tooltip: "Git branch. master will be used if not specified."
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.externalGit !== curValues.externalGit || curValues.externalGit === false
        },
        dependencies: ["externalGit", "useDatabase"],
        children: ({ getFieldValue }) => {
            const value = getFieldValue("externalGit");
            const useDatabase = getFieldValue("useDatabase") === true;
            if (useDatabase) return <></>

            return !value || value === false ? (
                <Field
                    component="input"
                    name="userName"
                    label="User Name"
                    tooltip="User name used to access the git remote"
                    preserve={false}
                    required={true}
                    dependencies={['externalGit']}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.externalGit !== curValues.externalGit || curValues.externalGit === false
        },
        dependencies: ["externalGit", "useDatabase"],
        children: ({ getFieldValue }) => {
            const value = getFieldValue("externalGit");
            const useDatabase = getFieldValue("useDatabase") === true;
            if (useDatabase) return <></>

            return !value || value === false ? (
                <Field
                    component="password"
                    name="password"
                    label="Password or Personal Access Token"
                    tooltip="Password or access token used to access the git remote"
                    preserve={false}
                    required={true}
                    dependencies={['externalGit']}
                    uniqe={false}
                />
            ) : null;
        },
    },
    {
        name: "syncBehavior",
        label: "Sync Behavior",
        component: "select",
        tooltip: "Specifies how PowerShell Universal will interact with git.",
        options: [
            { title: 'Two-Way', value: 0 },
            { title: 'One-Way', value: 1 },
            { title: 'Push Only', value: 2 },
        ]
    },
    {
        noStyle: true,
        shouldUpdate: () => true,
        dependencies: ["useDatabase"],
        children: ({ getFieldValue }) => {
            const useDatabase = getFieldValue("useDatabase") === true;
            if (useDatabase) {
                return <></>
            }

            return <Field
                component="inputNumber"
                name="interval"
                label="Sync Interval"
                tooltip="How often to run a git sync, in minutes. Defaults to every minute."
                preserve={false}
                required={true}
                uniqe={false}
                min={1}
                max={59}
            />
        }
    },
    {
        noStyle: true,
        shouldUpdate: () => true,
        dependencies: ["useDatabase"],
        children: ({ getFieldValue }) => {
            const useDatabase = getFieldValue("useDatabase") === true;
            if (useDatabase) {
                return <></>
            }

            return <Field
                component="switch"
                name="externalGit"
                label="Use External Git Client"
                tooltip="Use an external git client when performing git operations. This provides more functionality but can require additional, external configuration."
                preserve={false}
                required={true}
                uniqe={false}
                valuePropName={"checked"}
            />
        }
    },
    {
        name: "mode",
        label: "Mode",
        component: "select",
        tooltip: "Specifies how git commits are submitted to the repository. Manual mode is recommended.",
        initialValue: 0,
        options: [
            { title: 'Manual', value: 0 },
            { title: 'Automatic', value: 1 }
        ]
    },
    {
        noStyle: true,
        shouldUpdate: () => true,
        children: ({ getFieldValue }) => {
            return <Field
                component="switch"
                name="useDatabase"
                label="Bundle Git Repository in Database"
                tooltip="Stores the git repository in the database. This avoids having to setup a remote repository. This functionality requires a git client installed."
                preserve={false}
                uniqe={false}
                initialValue={false}
                valuePropName={"checked"}
            />
        }
    },
];
