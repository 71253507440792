import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Layout,
  Space,
  Alert,
  Card,
  Result,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { presetDarkPalettes } from "@ant-design/colors";
import { useQuery, useMutation } from "react-query3";
import { Identity, LoginPage as LoginPageType } from "./../../types";
import CustomLogin from "./customlogin";
import { Skeleton } from 'antd';
import axios from "axios";
import { toRelativeUrl } from "components/utils/utils";

// login to admin console
export function useLogin() {
  return useMutation(
    async (credentials: { username: string; password: any }) => {
      let res = await axios.post(
        toRelativeUrl(`/api/v1/signin${window.location.search}`),
        JSON.stringify(credentials),
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return res.data;
    }
    // {
    //   onError: (error: AxiosError, creds) => {
    //     // @ts-ignore
    //     return error.response.data?.errorMessage;
    //   },
    //   onSuccess: (data, creds) => {
    //     //@ts-ignore
    //     window.location.href = data?.returnUrl;
    //   },
    // }
  );
}

export default function LoginPage() {
  const { data, isLoading: isLoginPageLoading } = useQuery<LoginPageType>("/loginPage");
  useQuery<Identity>(`/identity/my`, {
    refetchOnWindowFocus: true, refetchOnReconnect: true, onSuccess: () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.returnUrl)
        window.location.href = params.returnUrl;
      else
        window.location.href = toRelativeUrl("/");
    }
  });
  const { data: version } = useQuery<string>("/version");
  const { data: buildVersion } = useQuery<any>("/version/build");
  const { mutateAsync, isLoading, data: userInfo } = useLogin();
  const [isAlive, setIsAlive] = useState(false);
  const { data: alive } = useQuery<any>('/alive', {
    refetchInterval: 1000,
    enabled: !isAlive,
    onSuccess(data) {
      setIsAlive(!data.loading || data.hasError);
    },
  });

  async function login(values: { username: string; password: any }) {
    await mutateAsync({
      username: values.username,
      password: values.password,
    }, {
      onSuccess: (data, variables) => {
        if (data?.returnUrl) {
          window.location.href = data?.returnUrl
        }
      }
    });
  }

  if (!alive || alive.loading) {
    return <Result title={alive?.hasError ? "PowerShell Universal Failed to Load" : "PowerShell Universal is loading..."} subTitle={<pre>{alive?.loadingInfo}</pre>} extra={alive?.hasError || <Skeleton active />} status={alive?.hasError ? "error" : "info"} />
  }

  if (isLoginPageLoading) {
    return <Skeleton />;
  }

  return !data.defaultLoginPage ? (
    <CustomLogin />
  ) : (
    <Layout
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space size={24} direction="vertical" align="center">
        <div style={{ width: 128, flex: "0" }}>
          <img
            alt="powershell universal logo"
            style={{ width: "inherit", height: "inherit" }}
            src={toRelativeUrl("admin/logo.png")}
          />
        </div>
        <Typography>
          <Space direction="horizontal" size={0}>
            <Typography.Text
              style={{
                color: presetDarkPalettes["blue"][6],
                fontFamily: "SFProDisplay-Black",
                fontSize: 24,
              }}
            >
              PowerShell
            </Typography.Text>
            <Typography.Text
              style={{
                fontFamily: "SFProDisplay-Semibold",
                fontSize: 24,
              }}
            >
              Universal
            </Typography.Text>
          </Space>
        </Typography>
        <Typography>
          <Typography.Paragraph
            style={{
              textAlign: "center",
              fontFamily: "SFProDisplay-Semibold",
              maxWidth: 340,
            }}
          >
            PowerShell Universal allows you to run jobs, schedule scripts, build
            web pages and APIs, and control access; all under a single pane of
            beautiful glass.
          </Typography.Paragraph>
        </Typography>

        <Card
          bordered={false}
          style={{
            maxWidth: 330,
            maxHeight: 360,
            padding: 24,
            flex: "unset",
            // backgroundColor: currentTheme === "dark" ? "#141414" : ,
          }}
        >
          <Space size={24} direction="vertical">
            <Typography style={{ width: "100%" }}>
              <Typography.Title
                level={5}
                style={{
                  width: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // color: "#DBDBDB",
                }}
              >
                Log in to your account
              </Typography.Title>
            </Typography>

            <Form
              name="normal_login"
              style={{ maxWidth: 330, maxHeight: 360 }}
              onFinish={(values) => login(values)}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your Username!" },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{ color: presetDarkPalettes["blue"][6] }}
                    />
                  }
                  autoFocus
                  bordered={false}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input
                  prefix={
                    <LockOutlined
                      style={{ color: presetDarkPalettes["blue"][6] }}
                    />
                  }
                  bordered={false}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            {userInfo?.errorMessage && (
              <Form.Item>
                <Alert message={
                  //@ts-ignore
                  userInfo?.errorMessage} type="error" showIcon />
              </Form.Item>
            )}
          </Space>
        </Card>
        {data.mode === "Demo" && <Alert type="info" message="PowerShell Universal Demo" description="Welcome to the PowerShell Universal demo! You can login with any user name or password."></Alert>}
        {data.mode !== "Demo" && data.defaultAuthentication && <Alert type="warning" message="Default Authentication" description="You have not configured authentication or authorization. The default user name is 'admin' and with a password of 'admin'. We recommend changing your password."></Alert>}

        <Typography>
          <Typography.Paragraph>PowerShell Universal {version} ({buildVersion?.version}) | Ironman Software © 2023</Typography.Paragraph>
        </Typography>
      </Space>
    </Layout>
  );
}
