import { EventHub } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const eventHubSchema: CustomSchema<EventHub>[] = [
    {
        name: "name",
        label: "Name",
        component: "input",
        required: false,
        uniqe: false,
    },
    {
        name: "description",
        label: "Description",
        component: "input",
        required: false,
        uniqe: false,
    },
    {
        name: "authenticated",
        label: "Authentication",
        component: "authSwitch",
        valuePropName: 'checked',
        required: false,
        uniqe: false,
    },
    {
        noStyle: true,
        shouldUpdate: (prevValues, curValues) => {
            return prevValues.authentication !== curValues.authentication || curValues.authentication === true
        },
        children: ({ getFieldValue }) => {
            return getFieldValue("authentication") === true ? (
                <Field
                    component="roleTags"
                    name="role"
                    label="Role"
                    preserve={false}
                    required={true}
                    dependencies={['authentication']}
                    uniqe={false}
                />
            ) : null;
        },
    },
];
